/*=========================================================
  F5 o'clock Main CSS
-----------------------------------------------------------
  Author: Brandon Kidd
=========================================================*/


/*---------------------*/
/*  General/Structure  */
/*---------------------*/
* {
  box-sizing: border-box;
}

body {
  background-color: #f3f3f3;
  color: #353535;
  font: 16px / 1.4 'Sintony', sans-serif;
  overflow-y: scroll;
}
body.theme-dark {
  background-color: #363636;
  color: #c5c5c5;
}

body::-webkit-scrollbar {
  width: 1.25rem;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 5px inset;
}
body.theme-dark::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 5px inset;
}

body::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.6);
  background-clip: padding-box;
  border: solid 0.25rem transparent;
  border-radius: 9999em;
  box-shadow: rgba(0, 0, 0, 0.6) 0 0 2px 0 inset;
}
body.theme-dark::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.10);
  border-color: rgba(0, 0, 0, 0.35);
  box-shadow: rgba(255, 255, 255, 0.25) 0 0 1px 0 inset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Libre Franklin', sans-serif;
}

a,
a:visited {
  color: #4ca4ff;
  text-decoration: none;
}
a:hover {
  color: #ffa64c;
  text-decoration: underline;
}
.theme-dark a:hover {
  color: #a580c5;
}

@keyframes rotateArrow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/*----------*/
/*  Main    */
/*----------*/
.Main {
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(44px + 1rem);
}

.Summary--social,
.Article--social {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75em;
  list-style: none;
  padding: 0;
}

.Social {
  align-items: center;
  display: flex;
}

.Social--label,
.Social--count {
  margin-left: 0.5em;
}

.Social--icon {
  width: 1rem;
  height: 1rem;
  display: block;
  fill: #353535;
}
.theme-dark .Social--icon {
  fill: #999;
}

.Social--label {
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}


/*--------------*/
/*  Sponsors    */
/*--------------*/
.Sponsor {
  position: relative;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  margin: 1rem auto 0;
  overflow: hidden;
  padding: 1.4rem 0.5rem 0.5rem;
  transition: opacity 250ms;
}
.Sponsor::before {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
  color: #e3e3e3;
  content: 'Advertisement';
  font-size: 0.625rem;
  line-height: 1;
  padding: 0.25em 0.5em;
}
.theme-dark .Sponsor {
  border-color: rgba(255, 255, 255, 0.15);
}
.theme-dark .Sponsor::before {
  background-color: rgba(255, 255, 255, 0.15);
  color: #222;
}
.Sponsor.leaderboard {
  margin: 1rem auto;
  order: 3;
}
.is-Refreshing .Sponsor {
  opacity: 0;
  position: absolute;
  left: -9999em;
}

.Advertisement {
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.theme-dark .Advertisement {
  background-color: rgba(255, 255, 255, 0.15);
}

.cube .Advertisement {
  width: 300px;
  height: 250px;
}

.leaderboard .Advertisement {
  width: 728px;
  height: 90px;
}

.Advertisement h3,
.Advertisement p {
  margin: 0;
}


/*-----------*/
/*  Loader   */
/*-----------*/
.Loader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  display: none;
  justify-content: center;
  text-align: center;
}
.is-Refreshing .Loader {
  display: flex;
}

.Loader--heading {
  margin: 0;
}

.Loader--animation {
  width: 4rem;
  height: 4rem;
  display: block;
  margin-left: -0.33rem;
}

.Loader--animation--circle {
  fill: #ffa64c;
}
.theme-dark .Loader--animation--circle {
  fill: #805e9c;
}


/*------------------*/
/*  Media Queries   */
/*------------------*/
@media screen and (max-width: 60em) {
  .Sponsor.leaderboard {
    margin: 1rem auto 0;
    order: 1;
  }

  .Content {
    order: 2;
  }

  .Sponsorships {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 0 1rem 1rem;
    order: 3;
  }
}

@media screen and (min-width: 60.01em) {
  .Content {
    width: 100%;
  }
}


/*-----------*/
/*  Helpers  */
/*-----------*/
.hidden {
  display: none !important;
}

.visuallyhidden {
  width: 1px;
  height: 1px;
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  width: auto;
  height: auto;
  position: static;
  clip: auto;
  margin: 0;
  overflow: visible;
  white-space: inherit;
}

.invisible {
  visibility: hidden;
}

.fw {
  flex-wrap: wrap;
}
