/*----------*/
/*  Header  */
/*----------*/
.Header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  box-shadow:
    rgba(0, 0, 0, 0.24) 0 2px 4px,
    rgba(0, 0, 0, 0.08) 0 4px 16px;
  display: flex;
}
.theme-dark .Header {
  background-color: #525252;
  box-shadow:
    rgba(0, 0, 0, 0.48) 0 2px 4px,
    rgba(0, 0, 0, 0.16) 0 4px 16px;
}

.Header .Logo {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 100;
  background: #fff;
  border-radius: 100%;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0 2px 2px,
    rgba(0, 0, 0, 0.15) 0 8px 8px;
  margin: 0.25rem auto -0.75rem;
  padding: 0.5rem 0.5rem 0.8rem;
  transform: translateX(-50%);
}
.Header .Logo::before,
.Header .Logo::after {
  width: 90%;
  height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  content: '';
  transform: translate3d(-50%, -50%, 0);
}
.Header .Logo::before {
  background: #fff;
  margin-top: -3px;
  z-index: 5;
}
.Header .Logo::after {
  z-index: 3;
  border: solid 2px #ccc;
}
.theme-dark .Header .Logo {
  background: #525252;
  box-shadow:
    rgba(0, 0, 0, 0.5) 0 2px 2px,
    rgba(0, 0, 0, 0.3) 0 8px 8px;
}
.theme-dark .Header .Logo::before {
  background: #525252;
}
.theme-dark .Header .Logo::after {
  border-color: #767676;
}

.Header .Logo--icon {
  height: 2.75rem;
  position: relative;
  z-index: 10;
  display: block;
  fill: #353535;
}
.theme-dark .Header .Logo--icon {
  fill: #e3e3e3;
}
.Header .Logo:hover .Logo--icon--cruft {
  fill: #ffa64c;
}
.theme-dark .Header .Logo:hover .Logo--icon--cruft {
  fill: #9e66cc;
}

.Header .Logo--icon--arrow,
.Header .Logo--icon--refreshing {
  transition: opacity 300ms;
}
.Header .Logo--icon--cruft {
  transition:
    fill 200ms,
    opacity 300ms;
}

.Header .Logo--icon--refreshing {
  opacity: 0;
}

.is-Refreshing .Logo--icon--arrow,
.is-Refreshing .Logo--icon--cruft {
  opacity: 0;
}

.is-Refreshing .Logo--icon--refreshing {
  animation: rotateArrow 1000ms 300ms infinite ease-in-out;
  opacity: 1;
  transform-origin: 50% 50%;
  transition-delay: 300ms;
}
