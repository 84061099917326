/* Preferences */
.Preferences {
  align-self: center;
  margin: 0.5rem 1rem 0.5rem auto;
}

.Preferences--Menu-Button {
  width: 2.75rem;
  height: 2.75rem;
  position: relative;
  border-radius: 0.15rem;
  cursor: pointer;
  margin: 0;
  opacity: 0.5;
  transition:
    background-color 150ms,
    opacity 100ms;
}
.Preferences--Menu-Button:hover,
.Preferences--Menu-Button.is-Expanded {
  opacity: 1;
}
.no-touchevents .Preferences--Menu-Button:hover {
  background-color: #4ca4ff;
}
.Preferences--Menu-Button.is-Expanded {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.24) 60%, rgba(0, 0, 0, 0.16) 100%);
  box-shadow: rgba(0, 0, 0, 0.25) 0 2px 4px 0 inset;
  transition: none;
}
.no-touchevents .Preferences--Menu-Button.is-Expanded:hover {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.24) 60%, rgba(0, 0, 0, 0.16) 100%),
              #4ca4ff;
}

.Preferences--Menu-Button--icon {
  width: 2rem;
  height: 2rem;
  fill: #353535;
  margin: 0.375rem;
  transition:
    fill 100ms,
    transform 200ms;
}
.theme-dark .Preferences--Menu-Button--icon {
  fill: #e3e3e3;
}
.Preferences--Menu-Button:hover .Preferences--Menu-Button--icon,
.Preferences--Menu-Button.is-Expanded .Preferences--Menu-Button--icon {
  transform: rotateZ(60deg);
}

.Preferences--List,
.Preference {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Preferences--List {
  position: absolute;
  top: 100%;
  right: 0;
  display: none;
  background-color: #eee;
  box-shadow:
    rgba(0, 0, 0, 0.24) 0 2px 4px,
    rgba(0, 0, 0, 0.08) 0 4px 16px;
  padding: 0.5rem 1rem;
}
.theme-dark .Preferences--List {
  background-color: #5b5b5b;
  box-shadow:
    rgba(0, 0, 0, 0.48) 0 2px 4px,
    rgba(0, 0, 0, 0.16) 0 4px 16px;
}

.Preferences--Menu-Button.is-Expanded + .Preferences--List {
  display: block;
}

.Preferences--List--item {
  max-width: 9999em;
  align-items: center;
  display: flex;
  opacity: 1;
  padding: 0.3125rem 0;
}
.SummaryView .Preferences--List--item.layout { display: none; }
.SummaryView .Preferences--List--item.layout + .Preferences--List--item {
  border-top: none;
  margin-top: 0;
  padding-top: 0.3125rem;
}
.Preferences--List--item.upvotes { flex-direction: column; }

.Preferences--List--item + .Preferences--List--item {
  border-top: solid 1px #d6d6d6;
  margin-top: 0.25rem;
  padding-top: 0.5rem
}
.theme-dark .Preferences--List--item + .Preferences--List--item {
  border-top-color: #6b6b6b;
}

.Preferences--Label,
.MultiPreference--Label {
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 1rem 0 0;
  user-select: none;
}

.Preferences--Label {
  width: 5rem;
}

.MultiPreference--Label {
  position: relative;
  cursor: pointer;
  display: flex;
}

.MultiPreference--Label.checkbox::before,
.MultiPreference--Label.checkbox::after {
  box-sizing: border-box;
  display: block;
}
.MultiPreference--Label.checkbox::before {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: solid 1px #ccc;
  content: '';
  margin: 0 5px 0 0;
}
.theme-dark .MultiPreference--Label.checkbox::before {
  background-color: #444;
  border-color: #777;
}
.MultiPreference--Label.checkbox:hover::before { border-color: #ffa64c; }
.theme-dark .MultiPreference--Label.checkbox:hover::before { border-color: #9e66cc; }
.MultiPreference--Label.checkbox::after {
  position: absolute;
  color: #ffa64c;
  content: '\002714';
  left: 2px;
  opacity: 0;
}
.theme-dark .MultiPreference--Label.checkbox::after {
  color: #9e66cc;
}
input[type="checkbox"]:checked + .MultiPreference--Label.checkbox::after {
  opacity: 1;
}

.Preferences--List--item select {
  font-size: 12px;
}

.Preference {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.24) 60%, rgba(0, 0, 0, 0.16) 100%);
  box-shadow: rgba(0, 0, 0, 0.25) 0 2px 4px 0 inset;
  border-radius: 9999em;
  display: flex;
  margin-left: auto;
}

.Preference--item {
  position: relative;
}

.MultiPreference {
  width: 100%;
  display: flex;
}
.MultiPreference + .MultiPreference {
  margin-top: 5px;
}

.MultiPreference--caption {
  font-size: 11px;
  line-height: 24px;
  opacity: 0.5;
  text-transform: uppercase;
}

.Option--text,
.Option--number,
.Option--select {
  width: 5rem;
  height: 24px;
  -webkit-appearance: none;
  background: #fff;
  border: solid 1px #ccc;
  border-radius: unset;
  display: block;
  font-size: 12px;
  margin-left: auto;
  padding: 0 5px;
}
.theme-dark .Option--text,
.theme-dark .Option--number,
.theme-dark .Option--select {
  background-color: #444;
  border-color: #666;
  color: #ddd;
}

.Option--radio,
.Option--checkbox {
  display: none;
}

.Option--label {
  cursor: pointer;
  display: block;
  padding: 0.25rem 0.5rem;
}
.Preference--item:first-of-type .Option--label { border-radius: 9999em 0 0 9999em; }
.Preference--item:last-of-type .Option--label { border-radius: 0 9999em 9999em 0; }
.Preference--item .Option--radio:not(:checked) + .Option--label {
  background: linear-gradient(to bottom, #d3d3d3 0%, #eee 48%, #cfcfcf 52%, #bbb 100%);
  box-shadow: rgba(0, 0, 0, 0.4) 0 0 3px 0 inset;
}
.Preference--item .Option--radio:not(:checked) + .Option--label .icon {
  fill: #353535;
  filter: drop-shadow(0 1px 1px rgba(255, 255, 255, 0.5));
}
.theme-dark .Preference--item .Option--radio:not(:checked) + .Option--label {
  background: linear-gradient(to bottom, #777 0%, #888 48%, #666 52%, #555 100%);
  box-shadow: rgba(255, 255, 255, 0.4) 0 0 2px 0 inset;
}
.theme-dark .Preference--item .Option--radio:not(:checked) + .Option--label .icon {
  fill: #ddd;
  filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.5));
}

.Preference--item .Option--label--icon {
  width: 1rem;
  height: 1rem;
  display: block;
  fill: #ffa64c;
  filter: drop-shadow(0 -1px 1px rgba(0, 0, 0, 0.25));
  transition: fill 100ms;
}
.Preference--item .Option--label:hover .icon {
  fill: #ffa64c;
}
.theme-dark .Preference--item .Option--label--icon,
.theme-dark .Preference--item .Option--label:hover .icon {
  fill: #9e66cc;
}