/*=========================================================
  F5 o'clock Home CSS
-----------------------------------------------------------
  Author: Brandon Kidd
=========================================================*/


/*------------*/
/*  Articles  */
/*------------*/
.ArticlesView .Articles {
  padding: 1rem 1rem 0;
  transition: opacity 250ms;
}
.is-Refreshing .ArticlesView .Articles {
  opacity: 0;
  position: absolute;
  left: -9999em;
}
.layout-table .ArticlesView .Articles {
  display: flex;
  flex-direction: column;
}

.ArticlesView .Articles--thead {
  width: 100%;
  display: none;
}
.layout-table .ArticlesView .Articles--thead {
  display: block;
  margin-bottom: 2px;
}

.ArticlesView .Articles--thead--tr {
  border-bottom: solid 1px rgba(0, 0, 0, 0.25);
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.theme-dark .ArticlesView .Articles--thead--tr {
  border-bottom-color: rgba(255, 255, 255, 0.25);
}

.ArticlesView .Articles--thead--th {
  color: #353535;
  font-size: 0.875em;
  padding: 0.5rem;
  text-transform: uppercase;
}
.theme-dark .ArticlesView .Articles--thead--th {
  color: #c5c5c5;
}
.ArticlesView .Articles--thead--th.upvotes,
.ArticlesView .Articles--thead--th.comments {
  width: 3.25rem;
}
.layout-table .ArticlesView .Articles--thead--th.posted {
  width: 7.5rem;
  text-align: right;
}
.layout-table .ArticlesView .Articles--thead--th.source {
  margin-left: auto;
  text-align: right;
}

.ArticlesView .Articles--thead--icon {
  width: 1.25rem;
  height: 1.25rem;
  display: block;
  fill: #353535;
  margin: 0 auto;
}
.theme-dark .ArticlesView .Articles--thead--icon {
  fill: #c5c5c5;
}

.ArticlesView .Article {
  width: 100%;
  position: relative;
  background: #fff;
  box-shadow:
    rgba(0, 0, 0, 0.12) 0 4px 8px,
    rgba(0, 0, 0, 0.24) 0 2px 4px;
  display: flex;
  flex-direction: column;
  transition:
    box-shadow 200ms,
    transform 200ms;
}
.theme-dark .ArticlesView .Article {
  background: #4c4c4c;
}
.layout-table .ArticlesView .Article {
  box-shadow: none;
  flex-direction: row;
}
.layout-table .ArticlesView .Article::before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffa64c;
  content: '';
}
.theme-dark.layout-table .ArticlesView .Article::before {
  background: #805e9c;
}
.layout-table .ArticlesView .Article:hover::before {
  background: #4ca4ff;
  opacity: 1 !important;
}
.no-touchevents .ArticlesView .Article:hover {
  box-shadow:
    rgba(0, 0, 0, 0.12) 0 8px 16px,
    rgba(0, 0, 0, 0.24) 0 4px 8px;
  transform: scale3d(1.05, 1.05, 1);
  z-index: 1000;
}
.no-touchevents .layout-table .ArticlesView .Article:hover {
  box-shadow: none;
  transform: none;
  z-index: inherit;
}

.layout-table .ArticlesView .Article + .Article {
  margin-top: 2px;
}

.layout-table .ArticlesView .Article--header::before,
.layout-table .ArticlesView .Article--summary,
.layout-table .ArticlesView .Social--icon,
.layout-table .ArticlesView .Social .Social--label,
.layout-table .ArticlesView .Top-Comment {
  display: none;
}

.ArticlesView .Article--header {
  position: relative;
}
.ArticlesView .Article--header::before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffa64c;
  content: '';
}
.theme-dark .ArticlesView .Article--header::before {
  background: #805e9c;
}
.ArticlesView .Article:hover .Article--header::before,
.theme-dark .ArticlesView .Article:hover .Article--header::before {
  background: #4ca4ff;
  color: #fff;
  opacity: 1 !important;
}
.layout-table .ArticlesView .Article--header {
  width: 100%;
  z-index: 30;
  order: 20;
}

.ArticlesView .Article--headline {
  position: relative;
  z-index: 10;
  font-size: 1.25rem;
  line-height: 1.25;
  margin: 0;
  padding: 1rem;
}

.ArticlesView .Article--headline a {
  z-index: 11;
  text-decoration: none;
  color: #000;
}

.theme-dark .ArticlesView .Article--header a {
  color: #fff;
}

.ArticlesView .Article:hover .Article--headline a {
  color: #fff;
}
.layout-table .ArticlesView .Article--headline {
  position: static;
  color: #151515;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0.75rem 0.5rem;
}
.theme-dark .ArticlesView .Article--headline {
  color: #fff;
}

.ArticlesView .Article--social {
  margin: 0.5rem 0.5rem 1rem;
}
.layout-table .ArticlesView .Article--social {
  width: calc((3.25rem * 2) + 2rem);
  z-index: 20;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: 0.875rem;
  margin: 0;
  order: 10;
}

.ArticlesView .Social {
  padding: 0.5rem 0.5rem 0;
}

.ArticlesView .Social--wrap {
  text-decoration: none;
  color: #000;
}

.theme-dark .layout-cards .Article:hover .Social--wrap {
  color: #fff;
}

.theme-light .layout-cards .ArticlesView .Article:hover .Social--wrap {
  color: #000;
}

.layout-table .ArticlesView .Article:hover .Social--wrap {
  color: #fff;
}

.theme-dark .Social--wrap {
  color: #fff;
}

.layout-table .ArticlesView .Social {
  width: 3.25rem;
  position: relative;
  padding: 0.75rem 0.5rem;
  text-align: center;
}

.layout-table .ArticlesView .Social--count {
  display: block;
  margin: 0 auto;
}

.ArticlesView .Article--summary {
  color: #151515;
  font-size: 0.8em;
  line-height: 1.65;
  margin: 0 0 1rem;
  padding: 0 1rem;
}
.theme-dark .ArticlesView .Article--summary {
  color: #fff;
}

.ArticlesView .Top-Comment {
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  border-left: solid 5px rgba(0, 0, 0, 0.25);
  font-size: 0.7em;
  margin: 0 1rem 1.5rem 2rem;
  padding: 0.75em 0.75em 0;
}
.theme-dark .ArticlesView .Top-Comment {
  background-color: rgba(255, 255, 255, 0.05);
  border-left: solid 5px rgba(255, 255, 255, 0.25);
}

.ArticlesView .Top-Comment p {
  font-style: italic;
  margin: 0 0 0.75em;
}

.ArticlesView .Top-Comment cite {
  font-style: normal;
}

.ArticlesView .Top-Comment figcaption {
  position: absolute;
  top: 100%;
  right: 0;
  color: #999;
  display: block;
  font-style: normal;
  margin-top: 0.25em;
}
.theme-dark .ArticlesView .Top-Comment figcaption {
  color: #999;
}

.ArticlesView .Article--footer {
  background-color: rgba(0, 0, 0, 0.05);
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  margin-top: auto;
  padding: 0.5rem 1rem;
}
.theme-dark .ArticlesView .Article--footer {
  background-color: rgba(0, 0, 0, 0.1);
}
.layout-table .ArticlesView .Article--footer {
  z-index: 10;
  background: transparent;
  border: none;
  margin: 0 0 0 auto;
  order: 30;
  padding: 0;
}

.ArticlesView .Article--source,
.ArticlesView .Article--posted {
  font-size: 12px;
  margin: 0;
}
.layout-table .ArticlesView .Article--source,
.layout-table .ArticlesView .Article--posted {
  font-size: 0.875rem;
  padding: 0.75rem 0.5rem;
  text-align: right;
  white-space: nowrap;
}

.ArticlesView .Article--posted {
  margin-left: auto;
}
.layout-table .ArticlesView .Article--posted {
  width: 7.5rem;
}

.ArticlesView .Article--summary-link {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 40;
  background: transparent;
  text-indent: -9999em;
}

.layout-table .ArticlesView .Article--headline,
.layout-table .ArticlesView .Article--source,
.layout-table .ArticlesView .Article--posted,
.layout-table .ArticlesView .Social {
  color: #000;
}
.theme-dark.layout-table .ArticlesView .Article--headline,
.theme-dark.layout-table .ArticlesView .Article--source,
.theme-dark.layout-table .ArticlesView .Article--posted,
.theme-dark.layout-table .ArticlesView .Social {
  color: #fff;
}
.layout-table .ArticlesView .Article:hover .Article--headline,
.layout-table .ArticlesView .Article:hover .Article--source,
.layout-table .ArticlesView .Article:hover .Article--posted,
.layout-table .ArticlesView .Article:hover .Social {
  color: #fff;
}


/*------------------*/
/*  Media Queries   */
/*------------------*/
@media screen and (max-width: 41.99em) {
  .ArticlesView .Articles {
    display: flex;
    flex-direction: column;
  }

  .ArticlesView .Article + .Article {
    margin-top: 1rem;
  }
}

@media screen and (min-width: 60.01em) {
  .layout-table .ArticlesView .Sponsorships {
    margin-top: 2.25rem;
  }
}

@media screen and (min-width: 42em) {
  .ArticlesView .Articles {
    display: grid;
    /* grid-gap: 1rem;
    grid-row-gap: 1rem; */
    grid-template-columns: repeat(auto-fit, minmax(319px, 1fr));
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

@media screen and
  (max-width: 799px),
  (min-width: 60.01em) and (max-width: 1149px) {
  .layout-table .ArticlesView .Articles--thead--th.source,
  .layout-table .ArticlesView .Article--source {
    display: none;
  }

  .layout-table .ArticlesView .Articles--thead--th.posted {
    margin-left: auto;
  }
}

@media screen and (max-width: 659px) {
  .layout-table .ArticlesView .Articles--thead--th.posted {
    display: none;
  }

  .layout-table .ArticlesView .Article {
    flex-wrap: wrap;
  }

  .layout-table .ArticlesView .Article--social {
    width: 7.5rem;
  }

  .layout-table .ArticlesView .Article--header {
    width: calc(100% - 7.5rem);
  }

  .layout-table .ArticlesView .Article--footer {
    margin-left: 7.5rem;
  }

  .layout-table .ArticlesView .Article--posted {
    font-size: 0.65rem;
    margin-top: -0.5rem;
    opacity: 0.5;
    padding-top: 0;
    text-align: left;
  }
}

/* Hotness */
.article-hotness-0 .hotness-bg::before,
.layout-table .article-hotness-0.Article::before { opacity: 0; }
.article-hotness-1 .hotness-bg::before,
.layout-table .article-hotness-1.Article::before { opacity: 0.01; }
.article-hotness-2 .hotness-bg::before,
.layout-table .article-hotness-2.Article::before { opacity: 0.02; }
.article-hotness-3 .hotness-bg::before,
.layout-table .article-hotness-3.Article::before { opacity: 0.03; }
.article-hotness-4 .hotness-bg::before,
.layout-table .article-hotness-4.Article::before { opacity: 0.04; }
.article-hotness-5 .hotness-bg::before,
.layout-table .article-hotness-5.Article::before { opacity: 0.05; }
.article-hotness-6 .hotness-bg::before,
.layout-table .article-hotness-6.Article::before { opacity: 0.06; }
.article-hotness-7 .hotness-bg::before,
.layout-table .article-hotness-7.Article::before { opacity: 0.07; }
.article-hotness-8 .hotness-bg::before,
.layout-table .article-hotness-8.Article::before { opacity: 0.08; }
.article-hotness-9 .hotness-bg::before,
.layout-table .article-hotness-9.Article::before { opacity: 0.09; }
.article-hotness-10 .hotness-bg::before,
.layout-table .article-hotness-10.Article::before { opacity: 0.1; }
.article-hotness-11 .hotness-bg::before,
.layout-table .article-hotness-11.Article::before { opacity: 0.11; }
.article-hotness-12 .hotness-bg::before,
.layout-table .article-hotness-12.Article::before { opacity: 0.12; }
.article-hotness-13 .hotness-bg::before,
.layout-table .article-hotness-13.Article::before { opacity: 0.13; }
.article-hotness-14 .hotness-bg::before,
.layout-table .article-hotness-14.Article::before { opacity: 0.14; }
.article-hotness-15 .hotness-bg::before,
.layout-table .article-hotness-15.Article::before { opacity: 0.15; }
.article-hotness-16 .hotness-bg::before,
.layout-table .article-hotness-16.Article::before { opacity: 0.16; }
.article-hotness-17 .hotness-bg::before,
.layout-table .article-hotness-17.Article::before { opacity: 0.17; }
.article-hotness-18 .hotness-bg::before,
.layout-table .article-hotness-18.Article::before { opacity: 0.18; }
.article-hotness-19 .hotness-bg::before,
.layout-table .article-hotness-19.Article::before { opacity: 0.19; }
.article-hotness-20 .hotness-bg::before,
.layout-table .article-hotness-20.Article::before { opacity: 0.2; }
.article-hotness-21 .hotness-bg::before,
.layout-table .article-hotness-21.Article::before { opacity: 0.21; }
.article-hotness-22 .hotness-bg::before,
.layout-table .article-hotness-22.Article::before { opacity: 0.22; }
.article-hotness-23 .hotness-bg::before,
.layout-table .article-hotness-23.Article::before { opacity: 0.23; }
.article-hotness-24 .hotness-bg::before,
.layout-table .article-hotness-24.Article::before { opacity: 0.24; }
.article-hotness-25 .hotness-bg::before,
.layout-table .article-hotness-25.Article::before { opacity: 0.25; }
.article-hotness-26 .hotness-bg::before,
.layout-table .article-hotness-26.Article::before { opacity: 0.26; }
.article-hotness-27 .hotness-bg::before,
.layout-table .article-hotness-27.Article::before { opacity: 0.27; }
.article-hotness-28 .hotness-bg::before,
.layout-table .article-hotness-28.Article::before { opacity: 0.28; }
.article-hotness-29 .hotness-bg::before,
.layout-table .article-hotness-29.Article::before { opacity: 0.29; }
.article-hotness-30 .hotness-bg::before,
.layout-table .article-hotness-30.Article::before { opacity: 0.3; }
.article-hotness-31 .hotness-bg::before,
.layout-table .article-hotness-31.Article::before { opacity: 0.31; }
.article-hotness-32 .hotness-bg::before,
.layout-table .article-hotness-32.Article::before { opacity: 0.32; }
.article-hotness-33 .hotness-bg::before,
.layout-table .article-hotness-33.Article::before { opacity: 0.33; }
.article-hotness-34 .hotness-bg::before,
.layout-table .article-hotness-34.Article::before { opacity: 0.34; }
.article-hotness-35 .hotness-bg::before,
.layout-table .article-hotness-35.Article::before { opacity: 0.35; }
.article-hotness-36 .hotness-bg::before,
.layout-table .article-hotness-36.Article::before { opacity: 0.36; }
.article-hotness-37 .hotness-bg::before,
.layout-table .article-hotness-37.Article::before { opacity: 0.37; }
.article-hotness-38 .hotness-bg::before,
.layout-table .article-hotness-38.Article::before { opacity: 0.38; }
.article-hotness-39 .hotness-bg::before,
.layout-table .article-hotness-39.Article::before { opacity: 0.39; }
.article-hotness-40 .hotness-bg::before,
.layout-table .article-hotness-40.Article::before { opacity: 0.4; }
.article-hotness-41 .hotness-bg::before,
.layout-table .article-hotness-41.Article::before { opacity: 0.41; }
.article-hotness-42 .hotness-bg::before,
.layout-table .article-hotness-42.Article::before { opacity: 0.42; }
.article-hotness-43 .hotness-bg::before,
.layout-table .article-hotness-43.Article::before { opacity: 0.43; }
.article-hotness-44 .hotness-bg::before,
.layout-table .article-hotness-44.Article::before { opacity: 0.44; }
.article-hotness-45 .hotness-bg::before,
.layout-table .article-hotness-45.Article::before { opacity: 0.45; }
.article-hotness-46 .hotness-bg::before,
.layout-table .article-hotness-46.Article::before { opacity: 0.46; }
.article-hotness-47 .hotness-bg::before,
.layout-table .article-hotness-47.Article::before { opacity: 0.47; }
.article-hotness-48 .hotness-bg::before,
.layout-table .article-hotness-48.Article::before { opacity: 0.48; }
.article-hotness-49 .hotness-bg::before,
.layout-table .article-hotness-49.Article::before { opacity: 0.49; }
.article-hotness-50 .hotness-bg::before,
.layout-table .article-hotness-50.Article::before { opacity: 0.5; }
.article-hotness-51 .hotness-bg::before,
.layout-table .article-hotness-51.Article::before { opacity: 0.51; }
.article-hotness-52 .hotness-bg::before,
.layout-table .article-hotness-52.Article::before { opacity: 0.52; }
.article-hotness-53 .hotness-bg::before,
.layout-table .article-hotness-53.Article::before { opacity: 0.53; }
.article-hotness-54 .hotness-bg::before,
.layout-table .article-hotness-54.Article::before { opacity: 0.54; }
.article-hotness-55 .hotness-bg::before,
.layout-table .article-hotness-55.Article::before { opacity: 0.55; }
.article-hotness-56 .hotness-bg::before,
.layout-table .article-hotness-56.Article::before { opacity: 0.56; }
.article-hotness-57 .hotness-bg::before,
.layout-table .article-hotness-57.Article::before { opacity: 0.57; }
.article-hotness-58 .hotness-bg::before,
.layout-table .article-hotness-58.Article::before { opacity: 0.58; }
.article-hotness-59 .hotness-bg::before,
.layout-table .article-hotness-59.Article::before { opacity: 0.59; }
.article-hotness-60 .hotness-bg::before,
.layout-table .article-hotness-60.Article::before { opacity: 0.6; }
.article-hotness-61 .hotness-bg::before,
.layout-table .article-hotness-61.Article::before { opacity: 0.61; }
.article-hotness-62 .hotness-bg::before,
.layout-table .article-hotness-62.Article::before { opacity: 0.62; }
.article-hotness-63 .hotness-bg::before,
.layout-table .article-hotness-63.Article::before { opacity: 0.63; }
.article-hotness-64 .hotness-bg::before,
.layout-table .article-hotness-64.Article::before { opacity: 0.64; }
.article-hotness-65 .hotness-bg::before,
.layout-table .article-hotness-66.Article::before { opacity: 0.65; }
.article-hotness-66 .hotness-bg::before,
.layout-table .article-hotness-66.Article::before { opacity: 0.66; }
.article-hotness-67 .hotness-bg::before,
.layout-table .article-hotness-67.Article::before { opacity: 0.67; }
.article-hotness-68 .hotness-bg::before,
.layout-table .article-hotness-68.Article::before { opacity: 0.68; }
.article-hotness-69 .hotness-bg::before,
.layout-table .article-hotness-69.Article::before { opacity: 0.69; }
.article-hotness-70 .hotness-bg::before,
.layout-table .article-hotness-70.Article::before { opacity: 0.7; }
.article-hotness-71 .hotness-bg::before,
.layout-table .article-hotness-71.Article::before { opacity: 0.71; }
.article-hotness-72 .hotness-bg::before,
.layout-table .article-hotness-72.Article::before { opacity: 0.72; }
.article-hotness-73 .hotness-bg::before,
.layout-table .article-hotness-73.Article::before { opacity: 0.73; }
.article-hotness-74 .hotness-bg::before,
.layout-table .article-hotness-74.Article::before { opacity: 0.74; }
.article-hotness-75 .hotness-bg::before,
.layout-table .article-hotness-75.Article::before { opacity: 0.75; }
.article-hotness-76 .hotness-bg::before,
.layout-table .article-hotness-76.Article::before { opacity: 0.76; }
.article-hotness-77 .hotness-bg::before,
.layout-table .article-hotness-77.Article::before { opacity: 0.77; }
.article-hotness-78 .hotness-bg::before,
.layout-table .article-hotness-78.Article::before { opacity: 0.78; }
.article-hotness-79 .hotness-bg::before,
.layout-table .article-hotness-79.Article::before { opacity: 0.79; }
.article-hotness-80 .hotness-bg::before,
.layout-table .article-hotness-80.Article::before { opacity: 0.8; }
.article-hotness-81 .hotness-bg::before,
.layout-table .article-hotness-81.Article::before { opacity: 0.81; }
.article-hotness-82 .hotness-bg::before,
.layout-table .article-hotness-82.Article::before { opacity: 0.82; }
.article-hotness-83 .hotness-bg::before,
.layout-table .article-hotness-83.Article::before { opacity: 0.83; }
.article-hotness-84 .hotness-bg::before,
.layout-table .article-hotness-84.Article::before { opacity: 0.84; }
.article-hotness-85 .hotness-bg::before,
.layout-table .article-hotness-85.Article::before { opacity: 0.85; }
.article-hotness-86 .hotness-bg::before,
.layout-table .article-hotness-86.Article::before { opacity: 0.86; }
.article-hotness-87 .hotness-bg::before,
.layout-table .article-hotness-87.Article::before { opacity: 0.87; }
.article-hotness-88 .hotness-bg::before,
.layout-table .article-hotness-88.Article::before { opacity: 0.88; }
.article-hotness-89 .hotness-bg::before,
.layout-table .article-hotness-89.Article::before { opacity: 0.89; }
.article-hotness-90 .hotness-bg::before,
.layout-table .article-hotness-90.Article::before { opacity: 0.9; }
.article-hotness-91 .hotness-bg::before,
.layout-table .article-hotness-91.Article::before { opacity: 0.91; }
.article-hotness-92 .hotness-bg::before,
.layout-table .article-hotness-92.Article::before { opacity: 0.92; }
.article-hotness-93 .hotness-bg::before,
.layout-table .article-hotness-93.Article::before { opacity: 0.93; }
.article-hotness-94 .hotness-bg::before,
.layout-table .article-hotness-94.Article::before { opacity: 0.94; }
.article-hotness-95 .hotness-bg::before,
.layout-table .article-hotness-95.Article::before { opacity: 0.95; }
.article-hotness-96 .hotness-bg::before,
.layout-table .article-hotness-96.Article::before { opacity: 0.96; }
.article-hotness-97 .hotness-bg::before,
.layout-table .article-hotness-97.Article::before { opacity: 0.97; }
.article-hotness-98 .hotness-bg::before,
.layout-table .article-hotness-98.Article::before { opacity: 0.98; }
.article-hotness-99 .hotness-bg::before,
.layout-table .article-hotness-99.Article::before { opacity: 0.99; }
.article-hotness-100 .hotness-bg::before,
.layout-table .article-hotness-100.Article::before { opacity: 1; }